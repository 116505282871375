const removeExpansion = (image) => {
   const truncatedPath = image.split('.').slice(0, -1).join('.');
   return truncatedPath
};


export function testWebP(image, webPSupported, setWebPSupported) {
   const webP = new Image();
   webP.src = 'data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA';
   webP.onload = webP.onerror = function () {
      webP.height === 2 ? setWebPSupported(true) : setWebPSupported(false)
   };

   return webPSupported ? removeExpansion(image) + '.webp' : image
};

