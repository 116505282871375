import React, { useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectFade } from 'swiper/modules';
import 'swiper/css/effect-fade';
import { v4 as uuidv4 } from 'uuid';
import { testWebP } from '../../imageUtils.js'
import LazyLoad from 'react-lazyload';

function OurValuesSwiper() {
   const [webPSupported, setWebPSupported] = useState(true);
   const slides = [
      { image: '../img/slider-index/our-values-image.jpg', alt: 'Орехи' },
      { image: '../img/slider-index/our-values-image2.jpg', alt: 'Помидоры' },
      { image: '../img/slider-index/our-values-image3.jpg', alt: 'Наши ценности' },
   ]

   return (
      <Swiper
         className="swiper sw_index"
         effect='fade'
         fadeEffect={{
            crossFade: true
         }}
         slidesPerView={1}
         speed={1000}
         loop={true}
         allowTouchMove={false}
         simulateTouch={false}
         autoplay={{
            delay: 3000,
            disableOnInteraction: false,
         }}
         modules={[Autoplay, EffectFade]}
      >
         {slides.map(item => (
            <SwiperSlide className="our_values__image" key={uuidv4()}>
               <LazyLoad height={0} offset={0}>
                  <img src={testWebP(item.image, webPSupported, setWebPSupported)} alt={item.alt} />
               </LazyLoad>
            </SwiperSlide>
         ))}
      </Swiper>
   )
}


export default OurValuesSwiper;