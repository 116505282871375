import React from 'react';
import Footer from '../components/Footer/Footer'
import ContactsHeader from '../components/Contacts/ContactsHeader/ContactsHeader';
import ContactMap from '../components/Contacts/ContactMap/ContactMap';
import { Helmet } from 'react-helmet';

function Contacts() {
   const url = window.location.href
   return (
      <div className="main_content index container">
         <Helmet>
            <title>Контакты</title>
            <meta name="title" content="Контакты" />
            <meta property="og:title" content="Контакты" />
            <meta property="twitter:title" content="Контакты" />

            <meta name="description"
               content="Кубанский производитель растительных продуктов питания. С 2016 года радуем вкусными и качественными продуктами" />
            <meta property="og:description"
               content="Кубанский производитель растительных продуктов питания. С 2016 года радуем вкусными и качественными продуктами" />
            <meta property="twitter:description"
               content="Кубанский производитель растительных продуктов питания. С 2016 года радуем вкусными и качественными продуктами" />

            <meta property="og:url" content={url} />
            <meta property="twitter:url" content={url} />
         </Helmet>
         <ContactsHeader />
         <ContactMap />
         <Footer />
      </div>
   );
}

export default Contacts;
