import React from 'react';
import Cooperate from '../components/Footer/Cooperate'
import Footer from '../components/Footer/Footer'
import BrandList from '../components/Brands/BrandList/BrandList';
import BrandHeader from '../components/Brands/BrandHeader/BrandHeader';
import '../components/Brands/BrandPage.css'
import { Helmet } from 'react-helmet';
import Skeleton from 'react-loading-skeleton';


function Brand() {
   const url = window.location.href
   return (
      <div className="main_content green container">
         <Helmet>
            <title>Наши бренды</title>
            <meta name="title" content="Наши бренды" />
            <meta property="og:title" content="Наши бренды" />
            <meta property="twitter:title" content="Наши бренды" />

            <meta name="description"
               content="Кубанский производитель растительных продуктов питания. С 2016 года радуем вкусными и качественными продуктами" />
            <meta property="og:description"
               content="Кубанский производитель растительных продуктов питания. С 2016 года радуем вкусными и качественными продуктами" />
            <meta property="twitter:description"
               content="Кубанский производитель растительных продуктов питания. С 2016 года радуем вкусными и качественными продуктами" />

            <meta property="og:url" content={url} />
            <meta property="twitter:url" content={url} />
         </Helmet>
         <BrandHeader />
         <BrandList />
         <Cooperate />
         <Footer />
      </div>
   );
}

export default Brand;
