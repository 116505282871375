import React from "react";
import './OurBrands.css';
import BrandList from "./BrandList";

class OurBrands extends React.Component {
   render() {
      return (
         <section className="section our_brand_index row">
            <div className="col-md-6">
               <div className="section__left_title">
                  <h2>НАШИ БРЕНДЫ</h2>
               </div>
            </div>
            <div className="col-md-6">
               <div className="our_brand__right">
                  <p>
                     Линейка продуктов включает в себя: соусы «Песто», разнообразные виды брускетт, а также
                     всевозможные намазки: хумусы, тофу-паштеты и веганские крем-чизы, тофу-десерты, кокосовые
                     конфеты, а также безглютеновые крекеры. Производим мы как под своими брендами: «Другой
                     Продукт», «Невинные закуски», «Casa Kubana», «Coco Nessa», «Народные намазки», так и под СТМ
                     других производителей.
                  </p>
               </div>
            </div>
            <div className="col-12">
               <BrandList />
            </div>
         </section>
      )
   }

}


export default OurBrands;