import React from "react";
import './BrandList.css';
import BrandListItems from "./BrandListItems";

class BrandList extends React.Component {
   render() {
      return (
         <section className="section brand_list page row">
            <BrandListItems />
         </section>
      )
   }

}


export default BrandList;