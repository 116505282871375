import React from "react";
import './HeaderTitle.css';


function HeaderTitle(props) {
   return (

      <section className="section index_top row">
         <h1>
            ДОБРО ПОЖАЛОВАТЬ НА САЙТ&nbsp;<br />
            КОМПАНИИ "ДРУГОЙ ПРОДУКТ"!
         </h1>
      </section>
   )


}


export default HeaderTitle;