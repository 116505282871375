import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Link } from 'react-router-dom'
import { testWebP } from '../../imageUtils'
import LazyLoad from 'react-lazyload';
import Skeleton from 'react-loading-skeleton';

function BrandListItems() {
   const [webPSupported, setWebPSupported] = useState(true);

   const brands = [
      { image: '../img/brands/INNOCENT-SNACKS.png', alt: 'Невинные закуски', title: 'Невинные закуски', link: "/snacks", target: '' },
      { image: '../img/brands/FOLK-NAMAZKI.png', alt: 'Народные намазки', title: 'Народные намазки', link: "/spreadings", target: '' },
      { image: '../img/brands/COCONESSA.png', alt: 'Coconessa', title: 'Coconessa', link: "https://coconessa.ru/", target: '_blank' },
      { image: '../img/brands/casa-kubana.png', alt: 'Casa Kubana', title: 'Casa Kubana', link: "https://casakubana.ru/", target: '_blank' },
   ];

   const [isLoaded, setIsLoaded] = useState(Array(brands.length).fill(false));
   const [keys, setKeys] = useState(brands.map(() => uuidv4()));

   const handleImageLoad = (index) => {
      const newImageStates = [...isLoaded];
      newImageStates[index] = true;
      setIsLoaded(newImageStates);
   };

   useEffect(() => {
      // Обновить ключи, если количество элементов изменилось
      if (brands.length !== keys.length) {
         setKeys(brands.map(() => uuidv4()));
      }
   }, [brands, keys]);

   return (
      brands.map((item, index) => (
         <div className="col-md-3" key={keys[index]}>
            <Link to={item.link} className="item" target={item.target}>
               <LazyLoad className="image" height={0} offset={0}>
                  {isLoaded[index] ? null : <Skeleton height={'100%'} />}
                  <img src={testWebP(item.image, webPSupported, setWebPSupported)} alt={item.alt}
                     title={item.title} onLoad={() => handleImageLoad(index)} />
               </LazyLoad>
               <h3>{item.title}</h3>
            </Link>
         </div>
      ))
   );
}

export default BrandListItems;
