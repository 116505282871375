import React from "react";
import './SnacksHead.css';

function SnacksHead() {

   const spanStyle = {
      position: "absolute",
      opacity: 0
   };

   return (
      <section className="section innocent_head row">
         <div className="col-xxl-6 col-xl-5">
            <h1>
               <img src="../img/innocent-snacks/innocent-snacks-logo.svg" alt="Невинные закуски"
                  title="Невинные закуски"/>
               <span style={spanStyle}>Невинные закуски</span>
            </h1>
         </div>
         <div className="col-xxl-6 col-xl-7 col-md-11">
            <div className="innocent_head__text">
               <p>
                  Топпинги под нашим любимым названием «Невинные закуски» - идеальный компаньон к багету или хлебцам
                  и, конечно же, бутылке вина. Мы производим закуски из растительных ингредиентов высокого качества:
                  отборные оливки и маслины, каперсы, белые грибы и другие изысканные компоненты, которые дополняют
                  вкус вина и создают верное гастрономическое настроение.
               </p>
            </div>
         </div>
      </section>
   )
}




export default SnacksHead;