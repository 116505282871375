import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { testWebP } from '../../imageUtils.js'
import LazyLoad from 'react-lazyload';
import Skeleton from 'react-loading-skeleton';

function SnackListItem() {
   const [webPSupported, setWebPSupported] = useState(true);

   const products = [
      {
         seoTitle: 'Топпинг для Брускетт "Зеленые оливки и кешью"',
         title: 'Зелёные оливки и кешью',
         text: `Оливки зеленые без косточки (оливки, вода, соль), вода питьевая, масло подсолнечное рафинированное дезодорированное, нут, паста из кешью, соль морская пищевая, чеснок сушеный, лимонный сок, перец черный молотый, перец красный молотый, регулятор кислотности (молочная кислота, лимонная кислота), антиокислитель (аскорбиновая кислота).`,
         image: '../img/innocent-snacks/green-olives-and-couscous.jpg',
         vine: 'white',
         cpf: {
            kcal: '120',
            kj: '493',
            proteins: '3',
            fats: '4',
            сarbohydrates: '18',
            wt: '90',
         }
      },
      {
         seoTitle: 'Топпинг для Брускетт "Вяленые томаты и сыр"',
         title: 'Вяленые томаты и сыр',
         text: `Томатная паста, масло подсолнечное рафинированное дезодорированное, томат вяленый восстановленный (томат сушеный, вода подготовленная), кешью, масло оливковое холодного отжима (Extra Virginia Olive Oil), сыр пармезан (молоко нормализованное пастеризованное, бактериальная закваска мезофильных молочнокислых микроорганизмов, молокосвертывающий фермент микробного происхождения, соль поваренная пищевая), базилик сушеный, соль морская пищевая, чеснок сушеный.`,
         image: '../img/innocent-snacks/dried-tomatoes-and-cheese.jpg',
         vine: 'red',
         cpf: {
            kcal: '350',
            kj: '1440',
            proteins: '4',
            fats: '30',
            сarbohydrates: '12',
            wt: '90',
         }
      },
      {
         seoTitle: 'Топпинг для Брускетт "Белые грибы"',
         title: 'Белые грибы',
         text: `Вода питьевая, пюре кабачковое, масло подсолнечное рафинированное дезодорированное, нут, грибы белые сушеные, сок лимона, кунжутная паста, соль морская пищевая, лук сушеный, чеснок сушеный, перец черный молотый.`,
         image: '../img/innocent-snacks/porcini-mushrooms.jpg',
         vine: 'red',
         cpf: {
            kcal: '236',
            kj: '987',
            proteins: '4',
            fats: '16',
            сarbohydrates: '18',
            wt: '90',
         }
      },
      {
         seoTitle: 'Топпинг для Брускетт "Свёкла и базилик"',
         title: 'Свёкла и базилик',
         text: `Вода питьевая, масло подсолнечное рафинированное дезодорированное, свекла пюре, нут, свекла сушеная, кунжутная паста, сок лимона, соль морская пищевая, базилик сушеный, кориандр молотый, чеснок сушеный, перец чёрный молотый, кумин молотый.`,
         image: '../img/innocent-snacks/beetroot-and-basil.jpg',
         vine: 'white',
         cpf: {
            kcal: '282',
            kj: '1180',
            proteins: '3',
            fats: '21',
            сarbohydrates: '20',
            wt: '90',
         }
      },
      {
         seoTitle: 'Топпинг для Брускетт "Базилик и кешью"',
         title: 'Базилик и кешью',
         text: `Базилик свежий, масло подсолнечное рафинированное дезодорированное, ядра орехов кешью, семена тыквы, ядра кедровых орехов, соль морская пищевая, масло оливковое нерафинированное высшего качества (Extra Virgin Olive Oil), чеснок сушёный, регуляторы кислотности (молочная кислота, лимонная кислота).`,
         image: '../img/innocent-snacks/basil-and-cashews.jpg',
         vine: 'white',
         cpf: {
            kcal: '590',
            kj: '2460',
            proteins: '7',
            fats: '59',
            сarbohydrates: '7',
            wt: '90',
         }
      },
   ]

   const [isLoaded, setIsLoaded] = useState(Array(products.length).fill(false));
   const [keys, setKeys] = useState(products.map(() => uuidv4()));

   const handleImageLoad = (index) => {
      const newImageStates = [...isLoaded];
      newImageStates[index] = true;
      setIsLoaded(newImageStates);
   };

   useEffect(() => {
      // Обновить ключи, если количество элементов изменилось
      if (products.length !== keys.length) {
         setKeys(products.map(() => uuidv4()));
      }
   }, [products, keys]);

   return (
      products.map((item, index) => (
         <div className="product_list__item row" key={keys[index]} title={item.seoTitle}>
            <div className="col-md-4">
               <LazyLoad className="image" height={0} offset={0}>
                  {isLoaded[index] ? null : <Skeleton height={'100%'} />}
                  <img src={testWebP(item.image, webPSupported, setWebPSupported)} alt={item.title}
                     title={item.title} onLoad={() => handleImageLoad(index)} />
               </LazyLoad>
            </div>
            <div className="col-xl-5 col-md-8">
               <div className="content">
                  <h2 className="content__title">{item.title}</h2>
                  <div className="content__text">
                     <p>
                        {item.text}
                     </p>
                  </div>
                  {item.vine === 'white' ?
                     <div className="content__ideal_vine">
                        <LazyLoad className="icon" height={0} offset={0}>
                           <img src={testWebP('../img/innocent-snacks/ideal-white-wine.png', webPSupported, setWebPSupported)} alt="Идеально к белому вину"
                              title="Идеально к белому вину" />
                        </LazyLoad>
                        ИДЕАЛЬНО <br />
                        К&nbsp;БЕЛОМУ ВИНУ
                     </div> :
                     <div className="content__ideal_vine">
                        <LazyLoad className="icon" height={0} offset={0}>
                           <img src={testWebP('../img/innocent-snacks/ideal-red-wine.png', webPSupported, setWebPSupported)} alt="Идеально к красному вину"
                              title="Идеально к красному вину" />
                        </LazyLoad>
                        ИДЕАЛЬНО <br />
                        К&nbsp;КРАСНОМУ ВИНУ
                     </div>
                  }
                  <button className="content__btn">Показать состав</button>
               </div>
            </div>
            <div className="col-xl-3">
               <div className="meta_wrap">
                  <button className="close">
                     <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="40" height="40" rx="20" fill="#F3EAD9" />
                        <rect x="11" y="11.92" width="24" height="4" transform="rotate(30 11 11.92)"
                           fill="#23482C" />
                        <rect x="9" y="23.92" width="24" height="4" transform="rotate(-30 9 23.92)" fill="#23482C" />
                     </svg>
                  </button>
                  <div className="title">{item.title}</div>
                  <div className="text">
                     <p>
                        {item.text}
                     </p>
                  </div>
                  <div className="meta">
                     <div className="meta__item">
                        <div className="name">
                           Энергетическая <br /> ценность
                        </div>
                        <div className="value">
                           <span>{item.cpf.kcal} ккал</span>
                           <span>{item.cpf.kj} кДж</span>
                        </div>
                     </div>
                     <div className="meta__item">
                        <div className="name">Белки</div>
                        <div className="value">{item.cpf.proteins} г</div>
                     </div>
                     <div className="meta__item">
                        <div className="name">Жиры</div>
                        <div className="value">{item.cpf.fats} г</div>
                     </div>
                     <div className="meta__item">
                        <div className="name">Углеводы</div>
                        <div className="value">{item.cpf.сarbohydrates} г</div>
                     </div>
                     <div className="meta__last">
                        <div className="value">{item.cpf.wt} г</div>
                        <div className="name">
                           Продукт готов
                           к&nbsp;употреблению
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      ))
   );
}

export default SnackListItem;