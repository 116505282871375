import React from "react";
import './OurValues.css';
import OurValuesSwiper from "./OurValuesSwiper";


class OurValues extends React.Component {
   render() {
      return (
         <section className="section our_values row">
            <div className="col-md-6">
               <div className="section__left_title">
                  <h2>
                     НАШИ ЦЕННОСТИ
                  </h2>
               </div>
            </div>
            <div className="col-md-6">
               <div className="our_values__right">
                  <p>
                     С 2016 года мы производим продукты с простым составом, используя сырье только растительного
                     происхождения, без консервантов, красителей и усилителей вкуса.
                  </p>
                  <p>
                     Наша главная задача –
                     производить вкусные и безопасные продукты питания, которые мы покупаем сами и делимся ими с
                     близкими людьми.
                  </p>
               </div>
            </div>
            <div className="col-12">
               <OurValuesSwiper />
            </div>
         </section>
      )
   }

}


export default OurValues;