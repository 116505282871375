import React from "react";
import './BrandHeader.css';

class BrandHeader extends React.Component {
   render() {
      return (
         <section className="section barnd_page_top row">
            <h1>
               НАШИ БРЕНДЫ
            </h1>
         </section>
      )
   }

}


export default BrandHeader;