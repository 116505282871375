import React from "react";
import './SpreadingsList.css';
import SpreadingsListItem from "./SpreadingsListItem";


class SpreadingsList extends React.Component {
   componentDidMount() {
      document.body.addEventListener('click', function (event) {
         const target = event.target;
         if (target.classList.contains('content__btn')) {
            document.querySelector('main').style.transform = 'unset';
            document.querySelector('main').style.willChange = 'unset';
            target.closest('.content__btn').parentElement.parentElement.nextElementSibling.classList.add('active');
         } else if (target.classList.contains('close')) {
            const parent = target.closest('.meta_wrap');
            parent.parentElement.classList.remove('active');
            setTimeout(() => {
               document.querySelector('main').style.willChange = 'transform, opacity';
            }, 400);
            event.preventDefault();
         } else if (target.classList.contains('col-xl-3')) {
            if (target.classList.contains('active')) {
               setTimeout(() => {
                  document.querySelector('main').style.willChange = 'transform, opacity';
               }, 400);
               target.classList.remove('active');
            }
         }
      });
   }
   render() {

      return (
         <section className="product_list">
            <SpreadingsListItem />
         </section>
      )
   }

}


export default SpreadingsList;