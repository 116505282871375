import React from 'react';
import Marquee from 'react-fast-marquee';
import { v4 as uuidv4 } from 'uuid';
import LazyLoad from 'react-lazyload';

function MarqueeCustom() {

   const images = [
      { image: '../img/team-index/100-natural.svg', alt: 'Без гмо' },
      { image: '../img/team-index/100-vegan.svg', alt: '100% Веган' },
      { image: '../img/team-index/100-natural.svg', alt: '100% Натуральное' },
      { image: '../img/team-index/lactose-free.svg', alt: 'Без лактозы' },
      { image: '../img/team-index/gluten-free.svg', alt: 'Без глютена' },
      { image: '../img/team-index/without-sugar.svg', alt: 'Без сахара' },
      { image: '../img/team-index/bez-konservantov.svg', alt: 'Без консервантов' },
      { image: '../img/team-index/without-eggs.svg', alt: 'Без яиц' },
   ]

   const totalImages = 20; // Общее количество изображений в бегущей строке

   // Дублируем изображения до достижения общего количества
   while (images.length < totalImages) {
      images.push(...images);
   }

   return (
      <div className="marquee">
         <Marquee speed={'60'}>
            {images.map(item => (
               <LazyLoad className="item" key={uuidv4()} height={0} offset={0}>
                  <img src={item.image} alt={item.alt} />
               </LazyLoad>
            ))}
         </Marquee>
      </div>
   );
}

export default MarqueeCustom;