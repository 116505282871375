import React, { useEffect, useState } from "react";
import './Header.css';
import { Link, useLocation } from 'react-router-dom'


function Header() {
   const [burgerActive, setBurgerActive] = useState(false)
   const [headerColor, setHeaderColor] = useState('white_bg');
   const [logoColor, setLogoColor] = useState();
   const location = useLocation();

   const toggleBurger = (e) => {
      e.preventDefault();
      setBurgerActive(!burgerActive);
   };

   const closeBurgerMenu = () => {
      setBurgerActive(false);
   };

   useEffect(() => {
      if (['/brands/', '/spreadings/', '/hummus/', '/brands', '/spreadings', '/hummus'].includes(location.pathname)) {
         setHeaderColor('green');
      } else if (['/snacks/', '/snacks'].includes(location.pathname)) {
         setHeaderColor('black');
      } else {
         setHeaderColor('white_bg');
      }
   }, [location.pathname]);

   useEffect(() => {
      if (headerColor === 'white_bg') {
         setLogoColor('green_logo');
      } else {
         setLogoColor('white_logo');
      }
   }, [headerColor]);

   return (
      <header className={`left_menu ${headerColor} ${burgerActive ? 'active' : ''}`}>
         <Link to="/" className={`logo ${logoColor}`}>
            <svg className="logo_desk" xmlns="http://www.w3.org/2000/svg" width="218" height="139" viewBox="0 0 218 139" fill="none">
               <title>Другой продукт</title>
               <path d="M51.4406 119.965H0.74707V137.927H51.4406V119.965Z" fill="#29D200"></path>
               <path d="M6.0166 11.0078V11.2305V115.735H23.9783V29.1922H28.2089V115.735H46.1706V11.0078H6.0166Z" fill="#29D200"></path>
               <path d="M153.421 20.2846H158.617V11.4521H153.421H144.812V20.2846V73.4274H153.421V20.2846Z" fill="#F2E9D8"></path>
               <path d="M187.711 54.3524L190.606 11.5264H181.477V73.4274H191.868L195.208 28.1521L197.286 28.3005L193.946 73.4274H203.149V11.5264H192.684L189.864 54.5008L187.711 54.3524Z" fill="#F2E9D8"></path>
               <path d="M108.37 62.9624C111.338 62.9624 114.678 62.814 116.905 59.9193C119.132 57.0989 118.835 51.5323 118.835 49.3056V24.8124C118.835 21.3982 119.057 17.5386 116.905 14.2729C114.753 11.0071 111.413 11.2298 108.37 11.2298H99.7598V73.2051H108.37V62.9624ZM108.37 20.0622H110.448V54.5754H108.37V20.0622Z" fill="#F2E9D8"></path>
               <path d="M132.491 62.2942V64.3724H123.51V73.2048H139.022L142.585 11.2295H132.491V54.5751H130.413V11.2295H120.393L123.51 62.2942H132.491Z" fill="#F2E9D8"></path>
               <path d="M97.9776 73.2048V64.3724H95.8993V20.0619V11.2295H85.2114H76.9728H76.6016V64.3724H74.5234V73.2048H97.9776ZM85.2114 20.0619H87.2896V64.3724H85.2114V20.0619Z" fill="#F2E9D8"></path>
               <path d="M179.399 42.4769V26.2966C179.399 23.2535 179.622 18.5775 178.138 15.6828C176.876 13.1593 174.204 10.71 169.751 10.71C165.297 10.71 162.625 13.1593 161.363 15.6828C159.879 18.5775 160.102 23.2535 160.102 26.2966V42.4769V42.4027V58.6573C160.102 61.7004 159.879 66.3764 161.363 69.271C162.625 71.7946 165.297 74.2439 169.751 74.2439C174.204 74.2439 176.876 71.7946 178.138 69.271C179.622 66.3764 179.399 61.7004 179.399 58.6573V42.4769ZM170.715 42.4769V64.6693H169.676H168.637V42.4769V42.4027V20.2846H169.676H170.715V42.4769Z" fill="#F2E9D8"></path>
               <path d="M217.253 76.0254H198.178V84.8578H203.447V138.001H212.057V84.8578H217.253V76.0254Z" fill="#F2E9D8"></path>
               <path d="M84.0242 76.0254H64.875H64.8008V138.001H73.4105V84.8578H75.4887V138.001H84.0985V76.0254H84.0242Z" fill="#F2E9D8"></path>
               <path d="M186.301 75.9512V102.597H184.223V76.0254H175.613V138.001H184.223V111.429H186.301V138.001H196.767L193.427 106.976L196.767 75.9512H186.301Z" fill="#F2E9D8"></path>
               <path d="M95.0824 127.684C98.0513 127.684 101.391 127.536 103.618 124.641C105.845 121.821 105.548 116.254 105.548 114.027V89.5341C105.548 86.1198 105.77 82.2603 103.618 78.9945C101.465 75.7288 98.1255 75.9514 95.0824 75.9514H86.4727V137.927H95.0824V127.684ZM95.0824 84.7839H97.1606V119.297H95.0824V84.7839Z" fill="#F2E9D8"></path>
               <path d="M164.036 127.09V129.168H154.981V138.001H170.567L174.056 76.0254H164.036V119.297H161.957V76.0254H151.863L154.981 127.09H164.036Z" fill="#F2E9D8"></path>
               <path d="M151.936 137.926V129.094H149.857V84.7836V75.9512H139.169H130.931H130.56V129.094H128.481V137.926H151.936ZM139.169 84.7836H141.248V129.094H139.169V84.7836Z" fill="#F2E9D8"></path>
               <path d="M127.222 106.976V90.8698C127.222 87.8267 127.444 83.1507 125.96 80.2561C124.698 77.7325 122.026 75.2832 117.573 75.2832C113.119 75.2832 110.448 77.7325 109.186 80.2561C107.701 83.1507 107.924 87.8267 107.924 90.8698V107.05V106.976V123.156C107.924 126.199 107.701 130.875 109.186 133.77C110.448 136.294 113.119 138.743 117.573 138.743C122.026 138.743 124.698 136.294 125.96 133.77C127.444 130.875 127.222 126.199 127.222 123.156V106.976ZM118.612 106.976V129.168H117.573H116.534V106.976V106.902V84.7094H117.573H118.612V106.976Z" fill="#F2E9D8"></path>
               <path d="M203.744 0.0222983C198.029 -0.348811 193.13 3.95606 192.685 9.67114C198.4 10.0423 203.373 5.73738 203.744 0.0222983Z" fill="#29D200"></path>
               <path d="M190.608 9.52286C185.709 9.22598 181.998 4.92111 182.295 0.0224609C187.194 0.39357 190.905 4.62422 190.608 9.52286Z" fill="#29D200"></path>
            </svg>
            <svg className="logo_mobile" width="215" height="49" viewBox="0 0 215 49" fill="none" xmlns="http://www.w3.org/2000/svg">
               <title>Другой продукт</title>
               <g clipPath="url(#clip0_1_2062)">
                  <path className="swtich" d="M74.0188 13.712H77.4146V8H74.0188H68.4707V13.712V48.08H74.0188V13.712Z" fill="#F3EAD9" />
                  <path className="swtich" d="M96.1621 35.696L97.9796 8H92.1445V48.032H98.7927L100.993 18.752L102.332 18.848L100.132 48.032H106.11V8H99.3188L97.5013 35.792L96.1621 35.696Z" fill="#F3EAD9" />
                  <path className="swtich" d="M45.0344 41.4581C46.9475 41.4581 49.0998 41.3621 50.5347 39.4901C51.9695 37.6661 51.7782 34.0661 51.7782 32.6261V16.7861C51.7782 14.5781 51.9217 12.0821 50.5347 9.97007C49.1476 7.85807 46.9954 8.00207 45.0344 8.00207H39.4863V48.1301H45.0344V41.4581ZM45.0344 13.7141H46.3736V36.0341H45.0344V13.7141Z" fill="#F3EAD9" />
                  <path className="swtich" d="M60.5792 41.024V42.368H54.7442V48.08H64.7881L67.036 8H60.5792V36.032H59.24V8H52.7832L54.7442 41.024H60.5792Z" fill="#F3EAD9" />
                  <path className="swtich" d="M38.3384 48.08V42.368H36.9992V8H30.1119H24.5638V42.32H23.2246V48.08H38.3384ZM30.1119 13.712H31.4511V42.368H30.1119V13.712Z" fill="#F3EAD9" />
                  <path className="swtich" d="M90.7575 28.496V18.08C90.7575 16.112 90.9009 13.088 89.9444 11.216C89.1313 9.584 87.4095 8 84.5398 8C81.6701 8 79.9483 9.584 79.1352 11.216C78.3221 12.848 78.3221 16.112 78.3221 18.08V28.544V38.96C78.3221 40.928 78.1786 43.952 79.1352 45.824C79.9483 47.456 81.6701 49.04 84.5398 49.04C87.4095 49.04 89.1313 47.456 89.9444 45.824C90.9009 43.952 90.7575 40.928 90.7575 38.96V28.496ZM85.2094 28.496V42.848H84.5398H83.8702V28.496V14.144H84.5398H85.2094V28.496Z" fill="#F3EAD9" />
                  <path className="swtich" d="M215.425 8H203.133V13.712H206.529V48.08H212.077V13.712H215.425V8Z" fill="#F3EAD9" />
                  <path className="swtich" d="M129.525 8H117.233H117.186V48.08H122.734V13.712H124.073V48.08H129.621V8H129.525Z" fill="#F3EAD9" />
                  <path className="swtich" d="M195.479 8V25.232H194.14V8.048H188.592V48.128H194.14V30.944H195.479V48.128H202.175L200.071 28.064L202.175 8H195.479Z" fill="#F3EAD9" />
                  <path className="swtich" d="M136.652 41.4581C138.565 41.4581 140.717 41.3621 142.152 39.4901C143.587 37.6661 143.395 34.0661 143.395 32.6261V16.7861C143.395 14.5781 143.539 12.0821 142.152 9.97007C140.765 7.85807 138.613 8.00207 136.652 8.00207H131.104V48.0821H136.652V41.4101V41.4581ZM136.652 13.7141H137.991V36.0341H136.652V13.6661V13.7141Z" fill="#F3EAD9" />
                  <path className="swtich" d="M181.131 41.024V42.368H175.296V48.08H185.292L187.588 8H181.131V35.984H179.792V8H173.287L175.296 41.024H181.131Z" fill="#F3EAD9" />
                  <path className="swtich" d="M173.288 48.08V42.368H171.948V8H165.061H159.513V42.32H158.174V48.08H173.288ZM165.061 13.712H166.4V42.368H165.061V13.664V13.712Z" fill="#F3EAD9" />
                  <path className="swtich" d="M157.41 28.496V18.08C157.41 16.112 157.553 13.088 156.597 11.216C155.784 9.584 154.062 8 151.192 8C148.322 8 146.601 9.584 145.788 11.216C144.831 13.088 144.974 16.112 144.974 18.08V28.544V38.96C144.974 40.928 144.831 43.952 145.788 45.824C146.744 47.696 148.322 49.04 151.192 49.04C154.062 49.04 155.784 47.456 156.597 45.824C157.553 43.952 157.41 40.928 157.41 38.96V28.496ZM151.862 28.496V42.848H151.192H150.523V28.496V14.144H151.192H151.862V28.496Z" fill="#F3EAD9" />
                  <path d="M106.462 0.0521555C106.211 3.7396 103 6.55092 99.3258 6.29953C99.5763 2.61209 102.787 -0.199229 106.462 0.0521555Z" fill="#29D300" />
                  <path d="M92.6406 0.067627C95.79 0.283099 98.1911 3.04539 97.9764 6.20606C94.827 5.99058 92.4259 3.22829 92.6406 0.067627Z" fill="#29D300" />
               </g>
               <path d="M15.4423 48.08V42.368H14.074V8H7.03699H1.3683V42.32H0V48.08H15.4423ZM7.03699 13.712H8.4053V42.368H7.03699V13.712Z" fill="#29D200" />
               <defs>
                  <clipPath id="clip0_1_2062">
                     <rect width="191.269" height="48" fill="white" transform="translate(23.7305)" />
                  </clipPath>
               </defs>
            </svg>
         </Link>
         <nav className={burgerActive ? 'active' : ''}>
            <ul>
               <li><Link to="/brands" onClick={closeBurgerMenu}>НАШИ БРЕНДЫ</Link></li>
               <li><Link to="/about" onClick={closeBurgerMenu}>О КОМПАНИИ</Link></li>
               <li><Link to="/contacts" onClick={closeBurgerMenu}>КОНТАКТЫ</Link></li>
            </ul>
         </nav>
         <div className="left_tel">
            <a href="tel:8(800)7377721">8 (800) 73 77 721</a>
         </div>
         <div className="btn_burger">
            <button className={`burger_trigger ${burgerActive ? 'active' : ''}`} onClick={toggleBurger} aria-label="Кнопка меню">
               <span></span>
            </button>
            <span className="hidden_reader">Кнопка меню</span>
         </div>
      </header>
   )


}


export default Header;