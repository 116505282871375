import React, { useState, useEffect } from "react";
import './OtherBrands.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from "react-router-dom";
import { testWebP } from '../imageUtils'
import { v4 as uuidv4 } from 'uuid';
import LazyLoad from 'react-lazyload';
import Skeleton from "react-loading-skeleton";

function OtherBrands({ brands }) {
   const [webPSupported, setWebPSupported] = useState(true);
   const [isLoaded, setIsLoaded] = useState(Array(brands.length).fill(false));
   const [keys, setKeys] = useState(brands.map(() => uuidv4()));

   const handleImageLoad = (index) => {
      const newImageStates = [...isLoaded];
      newImageStates[index] = true;
      setIsLoaded(newImageStates);
   };

   useEffect(() => {
      // Обновить ключи, если количество элементов изменилось
      if (brands.length !== keys.length) {
         setKeys(brands.map(() => uuidv4()));
      }
   }, [brands, keys]);

   return (
      <section className="section other_brands row">
         <div className="col-xl-6 col-md-6">
            <div className="section__left_title">
               <h2>ДРУГИЕ БРЕНДЫ</h2>
            </div>
         </div>
         <div className="col-12">
            <Swiper
               className="swiper sw_other_brands"
               slidesPerView={3}
               speed={600}
               spaceBetween={19}
               breakpoints={{
                  1: {
                     slidesPerView: 'auto',
                     spaceBetween: 15
                  },
                  767: {
                     slidesPerView: 3,
                     spaceBetween: 10
                  },
                  1200: {
                     slidesPerView: 3,
                     spaceBetween: 19
                  }
               }}
            >
               {brands.map((item, index) => (
                  <SwiperSlide className="swiper-slide item" key={keys[index]}>
                     <Link className="item" to={item.link} target={item.target}>
                        <LazyLoad className="image" height={0} offset={0}>
                           {isLoaded[index] ? null : <Skeleton height={'100%'} />}
                           <img src={testWebP(item.image, webPSupported, setWebPSupported)} alt={item.alt} title={item.title} onLoad={() => handleImageLoad(index)} />
                        </LazyLoad>
                        <h3>{item.title}</h3>
                     </Link>
                  </SwiperSlide>
               ))}
            </Swiper>
         </div>
      </section >
   )
}


export default OtherBrands;