import React, { useState } from "react";
import './AboutMeta.css';
import { testWebP } from '../imageUtils.js'
import LazyLoad from 'react-lazyload';
import Skeleton from "react-loading-skeleton";

function AboutMeta() {
   const [webPSupported, setWebPSupported] = useState(true);
   const [isLoaded, setIsLoaded] = useState(Array(3).fill(false)); // Используйте правильную длину массива для вашего случая

   const handleImageLoad = (index) => {
      const newImageStates = [...isLoaded];
      newImageStates[index] = true;
      setIsLoaded(newImageStates);
   };

   return (
      <section className="section about row">
         <div className="col-12">
            <h1>О КОМПАНИИ</h1>
         </div>
         <div className="col-12">
            <LazyLoad className="about__first_image" height={0} offset={0}>
               {isLoaded[0] ? null : <Skeleton height={'100%'} />}
               <picture>
                  <source srcSet={testWebP('../img/about/factory-mobile.jpg', webPSupported, setWebPSupported)} media="(max-width: 767px)" />
                  <img src={testWebP('../img/about/factory.jpg', webPSupported, setWebPSupported)} alt="Завод" onLoad={() => handleImageLoad(0)} />
               </picture>
            </LazyLoad>
         </div>
         <div className="offset-md-6 col-md-6">
            <div className="about__text">
               <p>
                  Производство находится в Краснодаре и специализируется на производстве паст, паштетов и соусов из
                  ингредиентов растительного происхождения.
               </p>
               <p>
                  Наши продукты подходят веганам, вегетарианцам и всем, кто стремится питаться правильно. Это вкусная
                  еда на каждый день с полезными натуральными ингредиентами и деликатными технологиями обработки.
               </p>
               <p>
                  Мы убеждены, что еда растительного происхождения — это именно еда, а не гарнир. Свойства наших
                  продуктов не уступают мясным — ни питательностью, ни яркостью вкуса.
               </p>
            </div>
         </div>
         <div className="col-12 second_image">
            <LazyLoad className="about__second_image" height={0} offset={0}>
               {isLoaded[1] ? null : <Skeleton height={'100%'} />}
               <picture>
                  <source srcSet={testWebP('../img/about/product-in-banks.jpg', webPSupported, setWebPSupported)} media="(max-width: 767px)" />
                  <img src={testWebP('../img/about/product-in-banks.jpg', webPSupported, setWebPSupported)} alt="Завод" onLoad={() => handleImageLoad(1)} />
               </picture>
            </LazyLoad>
         </div>
         <div className="about__item">
            <div className="row">
               <div className="col-md-6">
                  <LazyLoad className="about__item__other_image" height={0} offset={0}>
                     {isLoaded[2] ? null : <Skeleton height={'100%'} />}
                     <img src={testWebP('../img/about/other-product.jpg', webPSupported, setWebPSupported)} alt="" onLoad={() => handleImageLoad(2)} />
                  </LazyLoad>
               </div>
               <div className="col-md-6">
                  <div className="about__item__other_text">
                     <p>
                        Мы хотим предложить потребителю этичный продукт с лучшим соотношением вкуса, качества и
                        пользы.
                        Другой Продукт уделяет огромное внимание исходным ингредиентам: все сырье, поступающее на
                        фабрику,
                        проходит тщательный контроль — температура, свежесть, сертификаты соответствия. Значительная
                        часть
                        поставщиков — производители Юга России. Зелень, соя, чечевица, горох — все это выращено на
                        полях
                        Кубани и Дона.
                     </p>
                  </div>
               </div>
            </div>
         </div>
      </section>
   )


}


export default AboutMeta;