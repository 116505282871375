import React from "react";
import './OurProduct.css';
import 'react-loading-skeleton/dist/skeleton.css'

class OurProduct extends React.Component {
   render() {
      return (
         <section className="section our_brand row">
            <div className="col-md-6">
               <div className="section__left_title">
                  <h2>
                     НАШ ПРОДУКТ
                  </h2>
               </div>
            </div >
            <div className="col-md-6">
               <div className="our_brand__right">
                  <p>
                     Мы вдохновляемся кухнями народов мира и собираем информацию об особенностях каждого нашего
                     продукта во всех уголках разных стран. Так мы придумали тофу-паштет «Нешпротный» с японскими
                     водорослями Нори, ближневосточный хумус с белыми грибами из Карельских лесов, а так же
                     традиционный Генуезский соус «Песто», но со свежим базиликом, выращенным на полях
                     Кубани.
                  </p>
                  <p>
                     Сочетая кухни и нестандартные вкусы, мы создаем «другой продукт», который приходится по
                     вкусу многим нашим покупателям.
                  </p>

               </div>
            </div>
         </section >
      )
   }

}


export default OurProduct;