import React, { useState, useEffect } from 'react';
import { Routes, useLocation } from 'react-router-dom';
import { animated, useTransition } from 'react-spring';

const AnimatedRoutes = ({ children }) => {
  const location = useLocation();
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  useEffect(() => {
    // Установим isInitialLoad в false после первой загрузки страницы
    setIsInitialLoad(false);
  }, []);

  const transitions = useTransition(location, {
    from: isInitialLoad ? null : { opacity: 0, transform: 'translate3d(0%, -5%, 0)' },
    enter: { opacity: 1, transform: 'translate3d(0%, 0%, 0)' },
    leave: { opacity: 0, transform: 'translate3d(0%, 0%, 0)' },
  });

  return transitions((props, item) => (
    <animated.main style={{ ...props, position: 'absolute', width: '100%', right: '0px' }}>
      <div className="main_wrap">
        <Routes location={item}>{children}</Routes>
      </div>
    </animated.main>
  ));
};

export default AnimatedRoutes;
