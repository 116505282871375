import React from "react";
import './ContactMap.css';
import { YMaps, Map, Placemark } from 'react-yandex-maps';
import Skeleton from "react-loading-skeleton";

class ContactMap extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         url: 'https://yandex.ru/maps/-/CDVUjElM',
         mapLoaded: false,
         mapHeight: '100%', // Установите начальную высоту карты
      };
   }

   handleMapLoad = () => {
      // Установите новую высоту карты после загрузки
      this.setState({ mapLoaded: true, mapHeight: '100%' }); // Установите желаемую высоту
   }

   handleClick = () => {
      window.open(this.state.url, '_blank');
   }

   render() {
      const { mapLoaded, mapHeight } = this.state;

      return (
         <section className="section map_contact row">
            <div className="col-12">
               <YMaps query={{ apikey: 'd6a0fdf5-1b7e-487a-8d07-085b436a7865' }}>
                  <Map
                     className="map"
                     id="map"
                     style={{ height: mapHeight }} // Установите высоту карты
                     defaultState={{ center: [45.062845, 38.895484], zoom: 15 }}
                     instanceRef={ref => {
                        if (ref) {
                           if ('ontouchstart' in window) {
                              ref.behaviors.disable(['scrollZoom', 'drag']);
                           } else {
                              ref.behaviors.disable(['scrollZoom']);
                           }
                        }
                     }}
                     onLoad={this.handleMapLoad}
                  >
                     <Placemark
                        geometry={[45.062845, 38.895484]}
                        modules={['geoObject.addon.hint']}
                        properties={{
                           hintContent: `Россия, Краснодарский край, Г. Краснодар, Проезд Майский, Дом 16/3, Помещение 3.`,
                        }}
                        options={{
                           iconLayout: 'default#image',
                           iconImageHref: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='70' height='70' viewBox='0 0 70 70' fill='none'%3E%3Cpath d='M34.9999 0C19.0808 0 6.17578 12.9071 6.17578 28.8241C6.17578 44.7412 28.8305 70 34.9999 70C41.1672 70 63.8241 44.7412 63.8241 28.8241C63.8241 12.9071 50.9192 0 34.9999 0ZM34.9999 42.0539C27.6919 42.0539 21.7702 36.1301 21.7702 28.8241C21.7702 21.5161 27.6919 15.5944 34.9999 15.5944C42.3059 15.5944 48.2297 21.5161 48.2297 28.8241C48.2297 36.1301 42.3059 42.0539 34.9999 42.0539Z' fill='%2329D300'/%3E%3C/svg%3E",
                           iconImageSize: [45, 46],
                           iconImageOffset: [-25, -46],
                           hasBalloon: false,
                        }}
                        onMouseEnter={this.handleMouseEnter}
                        onClick={this.handleClick}
                     />
                  </Map>
               </YMaps>
               {!mapLoaded && (
                  <div className="box">
                     <Skeleton height={'100%'} />
                  </div>
               )}
            </div>
         </section >
      )
   }
}

export default ContactMap;
