import React from "react";
import './Team.css';
import MarqueeCustom from "./MarqueeCustom";

class Team extends React.Component {
   render() {
      return (
         <section className="section index_team row">
            <div className="col-xl-5 col-md-6">
               <div className="index_team__text">
                  <p>
                     Наша команда каждый день работает над созданием интересных сочетаний вкусов
                     из&nbsp;ингредиентов
                     растительного происхождения, не&nbsp;используя при этом глютен и&nbsp;лактозу.
                  </p>
               </div>
            </div>
            <div className="col-12">
               <MarqueeCustom />
            </div>
         </section >
      )
   }

}


export default Team;