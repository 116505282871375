import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToTop() {
   const { pathname } = useLocation();

   useEffect(() => {
      setTimeout(() => {
         window.scrollTo(0, 0);
      }, 50);
   }, [pathname]);


   // Возвращаем null, так как этот компонент ничего не рендерит
   return null;
}

export default ScrollToTop;
