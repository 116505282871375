import React from "react";
import './Cooperate.css';

class Cooperate extends React.Component {
   render() {
      return (
         <section className="section glad_cooperate row">
            <div className="col-xl-6 col-md-6">
               <div className="section__left_title">
                  <h2>МЫ РАДЫ СОТРУДНИЧЕСТВУ</h2>
               </div>
            </div>
            <div className="col-xl-5 col-md-6">
               <div className="glad_cooperate__text">
                  <p>КОМАНДА ООО «ДРУГОЙ ПРОДУКТ»</p>
                  <p>РОССИЯ, КРАСНОДАРСКИЙ КРАЙ, Г. КРАСНОДАР, ПРОЕЗД МАЙСКИЙ, ДОМ 16/3, ПОМЕЩЕНИЕ 3.</p>
                  <div className="links">
                     <a href="tel:8(800)7377721">8 (800) 73 77 721</a>
                     <a href="mailto:info@drugproduct.ru">INFO@DRUGPRODUCT.RU</a>
                  </div>
               </div>
            </div>
         </section>
      )
   }
}

export default Cooperate