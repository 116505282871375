import React from 'react';
import Footer from '../components/Footer/Footer';
import Cooperate from '../components/Footer/Cooperate';
import HeaderTitle from '../components/Index/HeaderTitle/HeaderTitle';
import OurProduct from '../components/Index/OurProduct/OurProduct';
import OurValues from '../components/Index/OurValues/OurValues';
import Team from '../components/Index/Team/Team';
import OurBrands from '../components/Index/OurBrands/OurBrands';
import OurPartner from '../components/Index/OurPartner/OurPartner';
import { Helmet } from 'react-helmet';

function FrontPage() {
   const url = window.location.href;

   return (
      <div className="main_content index container">
         <Helmet>
            <title>Главная</title>
            <meta name="title" content="Главная" />
            <meta property="og:title" content="Главная" />
            <meta property="twitter:title" content="Главная" />

            <meta name="description"
               content="Кубанский производитель растительных продуктов питания. С 2016 года радуем вкусными и качественными продуктами" />
            <meta property="og:description"
               content="Кубанский производитель растительных продуктов питания. С 2016 года радуем вкусными и качественными продуктами" />
            <meta property="twitter:description"
               content="Кубанский производитель растительных продуктов питания. С 2016 года радуем вкусными и качественными продуктами" />

            <meta property="og:url" content={url} />
            <meta property="twitter:url" content={url} />
         </Helmet>
         <HeaderTitle />
         <OurProduct />
         <OurValues />
         <Team />
         <OurBrands />
         <OurPartner />
         <Cooperate />
         <Footer />

      </div>
   );
}

export default FrontPage;
