import React from "react";
import './SpreadingsHead.css';

function SpreadingsHead() {


   const spanStyle = {
      position: "absolute",
      opacity: 0
   };
   return (
      <section className="section spreadings_head row">
         <h1>
            <img src="../img/spreadings/folk-spreadings.svg" alt="Народные намазки" title="Народные мазаки" />
            <span style={spanStyle}>Народные мазаки</span>
         </h1>
      </section>
   )
}




export default SpreadingsHead;