import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'swiper/css';
import App from './App.js';
import { hydrate, render } from "react-dom";

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<App />, rootElement);
} else {
  render(<App />, rootElement);
}
