import React from "react";
import './OurPartner.css';
import OurPartnerList from "./OurPartnerList";

class OurPartner extends React.Component {
   render() {
      return (
         <section className="section our_partner row">
            <div className="col-md-6">
               <div className="section__left_title">
                  <h2>НАШИ ПАРТНЕРЫ</h2>
               </div>
            </div>
            <div className="col-md-6">
               <OurPartnerList />
            </div>
         </section>
      )
   }

}


export default OurPartner;