import React from 'react';
import Cooperate from '../components/Footer/Cooperate'
import Footer from '../components/Footer/Footer'
import AboutMeta from '../components/About/AboutMeta';
import { Helmet } from 'react-helmet';

function About() {
   const url = window.location.href

   return (
      <div className="main_content index container">
         <Helmet>
            <title>О комании</title>
            <meta name="title" content="О комании" />
            <meta property="og:title" content="О комании" />
            <meta property="twitter:title" content="О комании" />

            <meta name="description"
               content="Кубанский производитель растительных продуктов питания. С 2016 года радуем вкусными и качественными продуктами" />
            <meta property="og:description"
               content="Кубанский производитель растительных продуктов питания. С 2016 года радуем вкусными и качественными продуктами" />
            <meta property="twitter:description"
               content="Кубанский производитель растительных продуктов питания. С 2016 года радуем вкусными и качественными продуктами" />

            <meta property="og:url" content={url} />
            <meta property="twitter:url" content={url} />
         </Helmet>
         <AboutMeta />
         <Cooperate />
         <Footer />
      </div>
   );
}

export default About;
