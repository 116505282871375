import React from "react";
import './Footer.css';


class Footer extends React.Component {
   render() {
      return (
         <section className="section footer row">
            <div className="col-xl-6 col-md-6">
               <div className="copy">
                  ©DRUGOYPRODUCT2023
               </div>
            </div>
            <div className="col-xl-6 col-md-6">
               <div className="develop">
                  ДИЗАЙН И РАЗРАБОТКА: <a href="https://kata.agency/" target="_blank" rel="noreferrer noopener nofollow">KATA.AGENCY</a>
               </div>
            </div>
         </section>
      )
   }
}

export default Footer

