import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { testWebP } from '../../imageUtils.js'
import LazyLoad from 'react-lazyload';

function OurPartnerList() {
   const [webPSupported, setWebPSupported] = useState(true);
   const partners = [
      { image: '../img/partner/magnit.jpg', alt: 'Магнит' },
      { image: '../img/partner/vkusvill.jpg', alt: 'Вкусвилл' },
      { image: '../img/partner/lenta.jpg', alt: 'Лента' },
      { image: '../img/partner/perekryostok.jpg', alt: 'Перекрёсток' },
      { image: '../img/partner/azbuka-vkusa.jpg', alt: 'Азбука вкуса' },
      { image: '../img/partner/okey.jpg', alt: "О'кей" },
      { image: '../img/partner/green.jpg', alt: 'Green' },
      { image: '../img/partner/tabris.jpg', alt: 'Табрис' },
   ]

   return (
      <div className="our_partner__list">
         {partners.map(item => (
            <LazyLoad className='item' key={uuidv4()} height={0} offset={0}>
               <img src={testWebP(item.image, webPSupported, setWebPSupported)} alt={item.alt} title={item.alt} loading='lazy' />
            </LazyLoad>
         ))}
      </div>
   );
}

export default OurPartnerList;