import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Link } from 'react-router-dom'
import { testWebP } from '../../imageUtils.js'
import LazyLoad from 'react-lazyload';

function BrandList() {
   const [webPSupported, setWebPSupported] = useState(true);

   const brands = [
      { image: '../img/brands/INNOCENT-SNACKS.png', alt: 'Невинные закуски', title: 'Невинные закуски', link: "/snacks", target: '' },
      { image: '../img/brands/FOLK-NAMAZKI.png', alt: 'Народные намазки', title: 'Народные намазки', link: "/spreadings", target: '' },
      { image: '../img/brands/COCONESSA.png', alt: 'Coconessa', title: 'Coconessa', link: "https://coconessa.ru/", target: '_blank' },
      { image: '../img/brands/casa-kubana.png', alt: 'Casa Kubana', title: 'Casa Kubana', link: "https://casakubana.ru/", target: '_blank' },
   ]

   return (
      <div className="brand_list row">
         {brands.map(item => (
            <div className="col-md-3 col-6" key={uuidv4()}>
               <Link to={item.link} className="item" target={item.target}>
                  <LazyLoad className="image" height={0} offset={0}>
                     <img src={testWebP(item.image, webPSupported, setWebPSupported)} alt={item.alt}
                        title={item.title} />
                  </LazyLoad>
                  <h3>{item.title}</h3>
               </Link>
            </div>
         ))}
      </div>
   );
}

export default BrandList;